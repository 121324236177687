export const ServerConfig = {
    serverMonit: {
        url: "hidl.monitafrica.com",
        web: "hidl.monitafrica.com",
        protocol: "https",
        google_captcha_key: '6LcoEawiAAAAAB5MbnCGOfdVoOFTxmW0LJb16TJ0'
    },
    server: {
        url: "hidl.afya.go.tz",
        web: "hidl.afya.go.tz",
        protocol: "https",
        google_captcha_key: '6LfkUcIUAAAAAALe_hBM22ci8dgBI7mk5POm2zWo'
    },
    serverLocal: {
        url: "localhost:8004",
        web: "localhost:4200",
        protocol: "http",
        google_captcha_key: '6LcoEawiAAAAAB5MbnCGOfdVoOFTxmW0LJb16TJ0'
    }
}
