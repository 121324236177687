import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { AuthService } from './auth/services/auth.service';
import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';
import { WHITELISTED_DOMAINS } from './util/config';
import { TokenService } from './auth/services/token.service';
import { AfterLoginService } from './guards/after-login/after-login.service';
import { OrganisantionService } from './organisations-management/services/organisantion.service';
import { ErrorNotfoundComponent } from './errors/components/error-notfound/error-notfound.component';
import { ErrorForbiddenComponent } from './errors/components/error-forbidden/error-forbidden.component';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';
import { PermissionsInitializerService } from './auth/services/permissions-initializer.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AddOrganisationComponent } from './user-management/components/add-organisation/add-organisation.component';
import { AddUserComponent } from './user-management/components/add-user/add-user.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { RejectUserComponent } from './user-management/components/reject-user/reject-user.component';
import { UpdateUserRoleComponent } from './user-management/components/update-user-role/update-user-role.component';
import { RejectOganisationComponent } from './user-management/components/reject-oganisation/reject-oganisation.component';
import { DocumentViewerComponent } from './user-management/components/document-viewer/document-viewer.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
// import { AddSystemComponent } from './user-management/components/add-system/add-system.component';
import { TreeviewModule } from 'ngx-treeview';
import { FileSaverModule } from 'ngx-filesaver';
import { AttachmentViewerComponent } from './user-management/components/attachment-viewer/attachment-viewer.component';
import { QuestionFormComponent } from './user-management/components/question-form/question-form.component';
import { HttpClientModule } from '@angular/common/http';
import { AnswersFormComponent } from './user-management/components/answers-form/answers-form.component';
import { UserModel } from './models/user.model';
import { LibraryDocumentViewerComponent } from './user-management/components/library-document-viewer/library-document-viewer.component';
import { RejectLibraryDocumentComponent } from './user-management/components/reject-library-document/reject-library-document.component';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { DepartmentFormComponent } from './user-management/components/department-form/department-form.component';
import { DocumentTypeFormComponent } from './user-management/components/document-type-form/document-type-form.component';
import { UpdateProfileComponent } from './user-management/components/update-profile/update-profile.component';
import { ResetPasswordComponent } from './user-management/components/reset-password/reset-password.component';
import { GtagModule } from 'angular-gtag';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { OrganisationRoleFormComponent } from './user-management/components/organisation-role-form/organisation-role-form.component';
import { GovernmentPlanFormComponent } from './user-management/components/government-plan-form/government-plan-form.component';
import { SystemUserFormComponent } from './user-management/components/system-user-form/system-user-form.component';
import { ObjectiveFormComponent } from './user-management/components/objective-form/objective-form.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { NgHttpLoaderModule } from "ng-http-loader";
import { PrincipleBusinessFormComponent } from './user-management/components/principle-business-form/principle-business-form.component';
import { OrganisationTypeFormComponent } from './user-management/components/organisation-type-form/organisation-type-form.component';
import { UserFeedbackComponent } from './user-management/user-feedback/user-feedback.component';
import { UserFeedbackCommentComponent } from './user-management/user-feedback-comment/user-feedback-comment.component';
import { DonorFormComponent } from './user-management/components/donor-form/donor-form.component';
import { CurrencyFormComponent } from './user-management/components/currency-form/currency-form.component';

export function tokenGetter(): string {
  const token = localStorage.getItem('token');
  return token;
}

@NgModule({
  declarations: [
    AppComponent,
    AddOrganisationComponent,
    AddUserComponent,
    ErrorNotfoundComponent,
    ErrorForbiddenComponent,
    ConfirmDialogComponent,
    RejectUserComponent,
    UpdateUserRoleComponent,
    RejectOganisationComponent,
    DocumentViewerComponent,
    AttachmentViewerComponent,
    // AddSystemComponent,
    DepartmentFormComponent,
    QuestionFormComponent,
    AnswersFormComponent,
    LibraryDocumentViewerComponent,
    RejectLibraryDocumentComponent,
    DocumentTypeFormComponent,
    OrganisationRoleFormComponent,
    GovernmentPlanFormComponent,
    PrincipleBusinessFormComponent,
    OrganisationTypeFormComponent,
    UpdateProfileComponent,
    UserFeedbackComponent,
    ResetPasswordComponent,
    SystemUserFormComponent,
    ObjectiveFormComponent,
    DonorFormComponent,
    CurrencyFormComponent,
    UserFeedbackCommentComponent
  ],
  imports: [
    GtagModule.forRoot({ trackingId: 'UA-146517291-1', trackPageviews: false }),
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    NgbModule,
    NgHttpLoaderModule.forRoot(),
    NgxPermissionsModule.forRoot(),
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains: WHITELISTED_DOMAINS
      }
    }),
    BsDatepickerModule.forRoot(),
    TreeviewModule.forRoot(),
    FileSaverModule,
    HttpClientModule,
    NgScrollbarModule,
    NgxDocViewerModule,
    ShareButtonsModule,
    AppRoutingModule,
  ],
  providers: [
    AuthService,
    TokenService,
    AfterLoginService,
    JwtHelperService,
    OrganisantionService,
    PermissionsInitializerService,
    FileSaverModule,
    NgxPermissionsService, {
      provide: APP_INITIALIZER,
      useFactory: (permissionIn: PermissionsInitializerService, ps: NgxPermissionsService) => {
        // const permissions = permissionIn.getPermissions();
        return (
          () => {
            permissionIn.getPermissions().subscribe(
              (res: { permissions: string[], user: UserModel }) => {
                const permissions = res.permissions;
                permissions.push('all');
                ps.loadPermissions(permissions);
              }
            );
          }
        )
          ;
      },
      deps: [PermissionsInitializerService, NgxPermissionsService],
      multi: true
    }
  ],
  exports: [],
  bootstrap: [AppComponent],
  entryComponents: [
    AddOrganisationComponent,
    AddUserComponent,
    ConfirmDialogComponent,
    RejectUserComponent,
    UpdateUserRoleComponent,
    RejectOganisationComponent,
    DocumentViewerComponent,
    AttachmentViewerComponent,
    // AddSystemComponent,
    QuestionFormComponent,
    AnswersFormComponent,
    LibraryDocumentViewerComponent,
    RejectLibraryDocumentComponent,
    DepartmentFormComponent,
    DocumentTypeFormComponent,
    UpdateProfileComponent,
    UserFeedbackComponent,
    ResetPasswordComponent,
    OrganisationRoleFormComponent, 
    PrincipleBusinessFormComponent,
    OrganisationTypeFormComponent,
    GovernmentPlanFormComponent,
    SystemUserFormComponent,
    ObjectiveFormComponent,
    DonorFormComponent,
    CurrencyFormComponent,
    UserFeedbackCommentComponent
  ]

})
export class AppModule {
}
