import {Injectable} from '@angular/core';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  constructor(
    private jwt: JwtHelperService
  ) {
  }


  set(token: string) {
    localStorage.setItem('token', token);
  }

  get(): string {
    return localStorage.getItem('token');
  }

  remove() {
    localStorage.removeItem('token');
  }

  isExpired(): boolean {
    return this.jwt.isTokenExpired();
  }

}
