import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as config from '../../util/config';
import {Observable} from 'rxjs';
import {Document, Reject} from '../../user-management/services/user.service';
import {OrganisationContactPerson, OrganisationModel} from '../../models/organisation.model';
import {PrincipleBusinessModel} from '../../models/principle-business.model';
import {OrganisationTypeModel} from '../../models/organisation-type.model';
import {PresenceModel} from '../../models/presence.model';
import {DocumentTypeModel} from '../../models/document-type.model';
import {UserModel} from '../../models/user.model';
import { QuestionModel } from '../../models/question.model';


class OrganisationPrincipalBusiness {
  created_at: string;
id: number;
organisation_id: number;
principal_business_id: number;
updated_at: string;
}

export interface Organisation {
  id?: number;
  name: string;
  website?: string;
  code?: string;
  email?: string;
  fax?: string;
  phone?: string;
  physical_address?: string;
  postal_address?: string;
  organisation_type_id?: number;
  organisation_type_other?: string;
  principal_business_id?: number;
  principal_businesses?: OrganisationPrincipalBusiness[];
  status?: 'VERIFIED' | 'PENDING' | 'REJECTED';
  presence_id?: number;
  organisation_account_status_id?: number;
  created_at?: number;
  departments?: Department[];
  legal_registration_type?: 'NGO' | 'COMPANY' | 'OTHER';
  legal_registration_no?: string;
  development_partner_number?: string;
  principal_business?: PrincipleBusiness;
  organisation_type?: OrganisationType;
  presence?: Presence;
  documents?: Document[];
  rejections?: Reject[];
  verified_users?: UserModel[];
  pending_users?: UserModel[];
  principal_business_other?: string;
  legal_registration_type_other?: string;
  contact_persons?: OrganisationContactPerson[];
}

export interface Department {
  id?: number;
  name?: string;
  code?: string;
  organisation_id?: number;
  created_at?: string;
}


export interface Presence {
  id?: number;
  name: string;
}

export interface OrganisationType {
  id?: number;
  name: string;
}

export interface PrincipleBusiness {
  id?: number;
  name?: string;
}

export interface OrganisationFormData {
  organisations?: [OrganisationModel];
  principal_businesses?: [PrincipleBusinessModel];
  organisation_types?: [OrganisationTypeModel];
  presences?: [PresenceModel];
  document_types?: [DocumentTypeModel];
}

export interface OrganisationsListDtResponse {
  data: OrganisationModel[];
  recordsFiltered: number;
  recordsTotal: number;
  draw: number;
}


@Injectable({
  providedIn: 'root'
})
export class OrganisantionService {

  constructor(
    private http: HttpClient
  ) {
  }


  getAll(): Observable<Organisation[]> {
    return this.http.get<Organisation[]>(config.API_URL + '/organisations');
  }

  save(formData: FormData): Observable<any> {
    return this.http.post(config.API_URL + '/admin/organisations/register', formData);
  }

  saveEdit(formData, id: number, reqType: 'admin' | 'own' = 'admin') {
    switch (reqType) {
      case 'own':
        return this.http.post(config.API_URL + '/user/update-organisation', formData);
        break;
      case 'admin':
        return this.http.patch(config.API_URL + '/admin/organisations/' + id, formData);
        break;
    }

  }

  getOrganisation(id: any): Observable<OrganisationModel> {
    return this.http.get<OrganisationModel>(config.API_URL + '/admin/organisations/' + id);
  }

  registerFormData(): Observable<OrganisationFormData> {
    return this.http.get<OrganisationFormData>(config.API_URL + '/admin/organisations/register-form-data');
  }

  allOrganisationsDt(datatableParameters: any): Observable<OrganisationsListDtResponse> {
    return this.http.post<OrganisationsListDtResponse>(config.API_URL + '/admin/organisations', datatableParameters);
  }

  allOrganisationsDtWithInitiatives(): Observable<{initiative_questions: QuestionModel[],organisations: OrganisationModel[]}> {
    return this.http.get<{initiative_questions: QuestionModel[],organisations: OrganisationModel[]}>(config.API_URL + '/organization/initiatives');
  }

  allPendigOrganisationsDt(datatableParameters: any): Observable<OrganisationsListDtResponse> {
    return this.http.post<OrganisationsListDtResponse>(config.API_URL + '/admin/pending-organisations?with_rejected=1', datatableParameters);
  }

  deleteOrganisation(id: number) {
    return this.http.delete(config.API_URL + '/admin/organisations/' + id);
  }

  approveOrganisation(id: number): Observable<{ message?: string, organisation?: OrganisationModel }> {
    return this.http.get<{ message?: string, organisation?: OrganisationModel }>(config.API_URL + `/admin/organisations/${id}/approve`);
  }

  rejectOrganisation(id: number, formData): Observable<{ message?: string, user?: UserModel }> {
    return this.http.post<{ message?: string, user?: UserModel }>(config.API_URL + `/admin/organisations/${id}/reject`, formData);
  }
}
