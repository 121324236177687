import { ServerConfig } from "config/server";

interface ServerConfigModel {
  url: string,
  web: string,
  protocol: string,
  google_captcha_key: string

}

const server: ServerConfigModel = ServerConfig.server;

// export const WEB_BASE_URL = `http://initiatives.afya.go.tz/#`;
export const WEB_BASE_URL = `${server.protocol}://${server.web}`;


export const GOOGLE_CAPTCHA_KEY = server.google_captcha_key;
export const DOMAIN_NAME = server.protocol + '://' +server.web;
export const COUNTRY_NAME = 'The United Republic Of Tanzania';
export const MINISTRY_NAME = 'Ministry of Health';
export const MINISTRY_SHORT_NAME = 'MoH';
export const SYSTEM_NAME = 'Health Inventory and Digital Library';
export const SYSTEM_SHORT_NAME = 'HIDL';

// export const GOOGLE_CAPTCHA_KEY ='6LfVVbQUAAAAAJUj9XlAvHtxtUQcwZbGwdLeMddO';//test
//  export const DOMAIN_NAME = `demo.egatest.go.tz/ehiidl-api`


//export const DOMAIN_NAME = '127.0.0.1:8000';


export const API_BASE_URL = `${server.protocol}://${server.url}`;
export const API_URL = API_BASE_URL + '/api';
export const MAX_UPLOAD_FILE_SIZE = 100 * 1024 * 1024;
export const MAX_UPLOAD_THUMBNAIL_SIZE = 1024 * 1024;
export const ALLOWED_FILE_TYPES = ['pdf'];
export const ALLOWED_FILE_TYPES_AND_IMAGES = ['pdf', 'jpg', 'jpeg', 'png'];
export const MAX_SHOW_ALL_ITEMS = 10000;
export const MINISTRY_FONT = 'lato';
export const MINISTRY_BG_COLOR = '#13548D';
export const DOC_URL = API_BASE_URL;
// export const DOC_URL = 'http://196.192.79.80';


export const UPLOAD_FILE_TYPE_ERROR = 'File type is not allowed. You can only upload PDF file type.';
export const UPLOAD_FILE_TYPE_AND_IMAGE_ERROR = 'File type is not allowed. You can only upload PDF,PNG and JPG file type.';

export const PAYLOD_URLS = {
  login: API_URL + '/login'
};

export const WHITELISTED_DOMAINS: string[] = [
  //'demo.egatest.go.tz'
  server.url,
  //'196.192.79.80'
  //'api-hidl.afya.go.tz'
];

