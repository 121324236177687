import {Component} from '@angular/core';
import {Spinkit} from "ng-http-loader";
import {API_URL} from "./util/config";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public spinkit = Spinkit;
  ignore: string[] = [
    API_URL + '/initiatives/suggest',
    API_URL + '/library/suggest',
    API_URL + '/facilities',
    API_URL + '/admin/organisations',
    API_URL + '/admin/users',
    API_URL + '/admin/pending-users',
    API_URL + '/admin/pending-organisations',
    API_URL + '/user/initiatives/list',
    API_URL + '/user/systems/list',
    API_URL + '/admin/initiatives/new-initiatives',
    API_URL + '/admin/systems/list',
    API_URL + '/user/my-initiatives',
    API_URL + '/user/my-systems',
    API_URL + '/user/libraries/list/published',
    API_URL + '/user/libraries/list/pending',
    API_URL + '/user/libraries/list/waiting-publication',
    API_URL + '/libraries/list/publish-rejected',
    API_URL + '/libraries/my-library',
    API_URL + '/admin/answers',
    API_URL + '/admin/department/list',
    API_URL + '/admin/document-type/list',
    API_URL + '/admin/system-user/list',
    API_URL + '/admin/organisation-role/list',
    API_URL + '/admin/government-plan/list',
    API_URL + '/admin/objective/list',
    API_URL + '/admin/initiative-questions',
    API_URL + '/admin/system-questions',
    API_URL + '/admin/library-questions',
    API_URL + '/dashboard/initiatives/list',
    API_URL + '/dashboard/systems/list',
    API_URL + '/dashboard/libraries/list/published'
  ];
  constructor() {

  }

}
