import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as config from '../../util/config';
import {Observable} from 'rxjs';
import {UserModel} from '../../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class PermissionsInitializerService {

  constructor(
    private http: HttpClient
  ) {
  }

  getPermissions(): Observable<{ permissions: string[], user: UserModel }> {
    return this.http.get<{ permissions: string[], user: UserModel }>(config.API_URL + '/user/permissions');
  }

}
