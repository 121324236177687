import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from './material-module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AlertsComponent } from '../partials/alerts/alerts.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DataTablesModule } from 'angular-datatables';
import { AddRoleComponent } from '../user-management/components/add-role/add-role.component';
import { NgxDocViewerModule } from 'ngx-doc-viewer';
import { FileUploadModule } from 'ng2-file-upload';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { DashboardComponent } from '../user-management/components/dashboard/dashboard.component';
import { ViewInitiativesComponent } from '../user-management/components/view-initiatives/view-initiatives.component';
import { ViewSystemsComponent } from '../user-management/components/view-systems/view-systems.component';
import { TreeviewModule } from 'ngx-treeview';
import { ViewLibraryComponent } from '../user-management/components/view-library/view-library.component';
import { NgxPermissionsModule } from 'ngx-permissions/';
import { InitiativeDetailsComponent } from "../user-management/components/initiative-details/initiative-details.component";
import { RouterModule } from "@angular/router";
import { SystemDetailsComponent } from "../user-management/components/system-details/system-details.component";
import { LibraryDetailsComponent } from "../user-management/components/library-details/library-details.component";
import { NgxCaptchaModule } from "ngx-captcha";
import { PieChartComponent } from "../user-management/components/charts/pie-chart/pie-chart.component";
import { HelpModalComponent } from './components/help-modal/help-modal.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { GeoMapChartComponent } from '../user-management/components/charts/geo-map-chart/geo-map-chart.component';
import { InitiativeDetailComponent } from '../user-management/report/initiative-report/budget-visibility/initiative-detail/initiative-detail.component';
import { TimelineDetailComponent } from '../user-management/report/initiative-report/timeline/timeline-detail/timeline-detail.component';
import { AssessmentDashboardComponent } from '../user-management/components/assessment-dashboard/assessment-dashboard.component';
import { StackedPercentColumnComponent } from '../user-management/components/charts/stacked-percent-column/stacked-percent-column.component';
import { ConfigurationTitleComponent } from './configuration-title/configuration-title.component';
import { PreviewAttachmentComponent } from './preview-attachment/preview-attachment.component';


@NgModule({
  declarations: [
    AlertsComponent,
    AddRoleComponent,
    FooterComponent,
    HeaderComponent,
    DashboardComponent,
    ViewInitiativesComponent,
    ViewSystemsComponent,
    ViewLibraryComponent,
    InitiativeDetailsComponent,
    InitiativeDetailComponent,
    TimelineDetailComponent,
    SystemDetailsComponent,
    LibraryDetailsComponent,
    PieChartComponent,
    GeoMapChartComponent,
    StackedPercentColumnComponent,
    AssessmentDashboardComponent,
    HelpModalComponent,
    ConfigurationTitleComponent,
    PreviewAttachmentComponent,
    // DocumentTypeSummaryComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NgSelectModule,
    DataTablesModule,
    NgxDocViewerModule,
    FileUploadModule,
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TreeviewModule,
    NgxPermissionsModule,
    RouterModule,
    ShareButtonsModule
  ],
  exports: [
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    AlertsComponent,
    NgSelectModule,
    DataTablesModule,
    NgxDocViewerModule,
    FileUploadModule,
    BsDropdownModule,
    ModalModule,
    BsDatepickerModule,
    FooterComponent,
    HeaderComponent,
    ConfigurationTitleComponent,
    PreviewAttachmentComponent,
  ],
  entryComponents: [
    AddRoleComponent,
    HelpModalComponent
  ]
})
export class SharedModule {
}
