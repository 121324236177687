import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as config from '../../util/config';
import {Observable, Subject} from 'rxjs';
import {TokenService} from './token.service';
import {Router, UrlSerializer} from '@angular/router';
import {UserModel} from '../../models/user.model';
import {User} from "../../user-management/services/user.service";
import {NgxPermissionsService} from "ngx-permissions";


export interface LoginResponse {
  status: 'verify' | 'reset' | 'admin_verify';
  token?: string;
  access_token: string;
  expires_in: number;
  token_type: string;
  user: UserModel;
  permissions: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private user: UserModel;

  private _mimi = new Subject<UserModel>();

  constructor(
    private http: HttpClient,
    private token: TokenService,
    private router: Router,
    private permissions: NgxPermissionsService,
    private url: UrlSerializer
  ) {
  }

  postLogin(form: { email: string, password: string, remember_me?: string }): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(config.API_URL + '/auth/login', form);
  }

  isLoggedIn(): boolean {
    if (this.token.isExpired()) {
      this.clearShouldResetPassword();
    }
    return !this.token.isExpired();
  }

  logout() {
    this.token.remove();
    this.clearShouldResetPassword();
    this.permissions.flushPermissions();
    return this.router.navigate(['home']);
  }

  forgotPassword(formData: any) {
    return this.http.post(config.API_URL + '/auth/forgot-password', formData);
  }

  checkIfShouldResetPassword(): boolean {
    const status = localStorage.getItem('shouldResetPwd');
    return (status === 'YES' && this.isLoggedIn()) ? true : false;
  }

  shouldResetPassword() {
    localStorage.setItem('shouldResetPwd', 'YES');
  }

  clearShouldResetPassword() {
    localStorage.removeItem('shouldResetPwd');
  }

  resetPassword(formData: any) {
    return this.http.post(config.API_URL + '/user/reset-password', formData);
  }

  subscribeToNewsletter(formData: any) {
    return this.http.post(config.API_URL + '/user/subscribe', formData);
  }


  verifyEmail(id: any, expires: string, signature: string): Observable<{ message: string, status?:  'VERIFIED' | 'PENDING' | 'REJECTED'}> {
    return this.http.get<{ message: string, status?:  'VERIFIED' | 'PENDING' | 'REJECTED' }>(config.API_URL + '/email/verify/' + id + '?expires=' + expires + '&signature=' + signature);
  }

  resendEmailVerification(formData: any): Observable<{ message: string }> {
    return this.http.post<{ message: string }>(config.API_URL + '/auth/resend-verification-email', formData);
  }

  getMe(): Observable<UserModel> {
    return this.http.get<UserModel>(config.API_URL + '/auth/me');
  }

  set me(user: UserModel) {
    this.user = user;
  }

  get me(): UserModel {
    return this.user;
  }

  get mimi() {
    return this._mimi.asObservable();
  }

  getObservableMe() {
    this.http.get<UserModel>(config.API_URL + '/auth/me').subscribe(
      (res: UserModel) => {
        this.me = res;
        this._mimi.next(res);
      }
    );
  }

}
