import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AfterLoginService } from './guards/after-login/after-login.service';
import { ErrorNotfoundComponent } from './errors/components/error-notfound/error-notfound.component';
import { ErrorForbiddenComponent } from './errors/components/error-forbidden/error-forbidden.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule) },
  { path: 'landing-page', loadChildren: () => import('./landing-page/landing-page.module').then(m => m.LandingPageModule) },
  // { path: 'library', loadChildren: () => import('./library-page/library-page.module').then(m => m.LibraryPageModule) },
  { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) },
  {
    path: 'user-management',
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
    canActivate: [AfterLoginService]
  },
  {
    path: 'reports',
    loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
    canActivate: [AfterLoginService]
  },
  { path: 'not-found', component: ErrorNotfoundComponent },
  { path: 'access-forbidden', component: ErrorForbiddenComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
